import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../mainComponent/mainInput";
import { useRecoilState } from "recoil";
import AuthApi from "../../../services/AuthApi";
import { setLoggedIn } from "../../../recoil/atom/setLoggedIn";
import CreateForm from "./createnewmpin";

function ShowCreateOtp(props) {
    const { otp, _id, isSubscribed, isLoggedIn, mobile } = props.loginData;
    const getOTP = otp
    // const [id, setId] = useRecoilState(setDoctorId)
    const { loginOtp } = AuthApi()
    const navigate = useNavigate()
    const [loginotp, setLoginOtp] = useState('');
    const [loggedIn, setLoggedin] = useRecoilState(setLoggedIn);
    const [errormessage, setErrormessage] = useState(false);
    const [data, setData] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        loginOtp({ getOTP, _id }) //axios call
            .then(response => {
                setLoggedin(isLoggedIn)
                // setId(_id)
                if (getOTP !== loginotp) {
                    setErrormessage(true);
                } else {
                    setData(true)
                }
            })
    }

    return (
        <>
            <div className="row">
                <>
                    {getOTP === loginotp && data === true ? <CreateForm mobile={mobile} _id={_id} /> :
                        <>
                            <div className=" mr-2">
                                <MainInput
                                    type="text"
                                    name="otp"
                                    maxLength={6}
                                    onChange={(e) => setLoginOtp(e.target.value)}
                                    placeholder="6 digit OTP" >
                                </MainInput>
                            {errormessage && (<span className="validation mr-4">Please enter correct OTP</span>)}
                            </div>
                            <div className="width_20 ">
                                <MainButtonInput onClick={handleSubmit}>Create Mpin</MainButtonInput>
                            </div>
                        </>
                    }
                </>
            </div>
        </>
    )
}
export { ShowCreateOtp }