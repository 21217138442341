import React, { useState } from 'react'
import { MainInput } from '../../../mainComponent/mainInput';
import { Button } from 'react-bootstrap'
import LabWorkApi from '../../../services/LabWorkApi';
import { validateForm } from '../validateForm';

export default function AddWork(props) {
    const { onClick } = props;
    const { addWorkType } = LabWorkApi()
    const [work, setWork] = useState([])
    const [errors, setErrors] = useState({})
    const handleChange = (e) => {
        const { name, value } = e.target;
        setWork({ ...work, [name]: value });
    }

    const AddData = () => {
        const validation = validateForm({
            work
        })
        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        const bodyData = {
            "worktype": work.worktype,
            "price": work.price,
        }
        addWorkType(bodyData)
        onClick()
    }
    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <label className="font_weight">Work Type</label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={work.worktype}
                        name="worktype"
                        placeholder='Work type'
                    >
                    </MainInput>
                    {errors.worktype && <div className="validation mb-1">{errors.worktype}</div>}

                </div>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label className="font_weight">Work Type Price</label>
                </div>
                <div className="col-md-7 ">
                    <MainInput
                        type="text"
                        onChange={(e) => handleChange(e)}
                        value={work.price}
                        name="price"
                        placeholder='Price'
                    >
                    </MainInput>
                    {errors.price && <div className="validation mb-1">{errors.price}</div>}
                </div>
            </div>

            <Button
                type="submit"
                onClick={AddData}
                variant="default"
                className='appColor btn_sub mr-3'>
                Add
            </Button>

        </>
    )
}