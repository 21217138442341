import React, { useState, useEffect } from "react";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../../mainComponent/mainInput";
import ClinicApi from "../../../../services/ClinicApi";
import { Autocomplete, TextField } from "@mui/material";
import { API } from "../../../../config";
import axios from "axios";

const AddClinic = (props) => {
    const { doctorId } = props;
    const [coilDoctorClinicData, setCoilDoctorClinicData] = useRecoilState(setDoctorClinic)
    const [selectedService, setSelectedService] = useState([]);
    const [clinicInfo, setClinicInfo] = useState([]);
    const [file, setFile] = useState(null);
    const [servicess, setServicess] = useState([])
    const { getServicess, addAnotherClinic } = ClinicApi()
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
        fetchServicess()
    }, []);

    const fetchServicess = () => {
        getServicess()
            .then((res) => {
                setServicess(res)
            })
    }

    const handleFileChange = (event) => {
        setClinicInfo({ ...clinicInfo, clinicLogo: URL.createObjectURL(event.target.files[0]) })
        setFile(event.target.files[0]);
    };
    function handleChange(event) {
        const { name, value } = event.target;
        setClinicInfo({ ...clinicInfo, [name]: value })
    }
    const handleService = (e, selectedValue) => {
        e.preventDefault()
        setSelectedService(selectedValue)
    }

    const handleValidation = () => {
        const tempErrors = {};
        let formIsValid = true;

        // Name validation
        if (!clinicInfo.clinicName) {
            tempErrors.name = "Clinic name is required ";
            formIsValid = false;
        }
        if (!clinicInfo.address) {
            tempErrors.address = "City & Area is required";
            formIsValid = false;
        }
        if (selectedService.length === 0) {
            tempErrors.services = "Select atleast one service";
            formIsValid = false;
        }

        setErrors(tempErrors);
        setIsValid(formIsValid);
        return formIsValid;
    };

    async function sendClinicInfo(e) {
        e.preventDefault();
        if (!handleValidation()) {
            return;
        }
        const formData = new FormData();
        formData.append('doctorId', doctorId);
        formData.append('photo', file);
        formData.append('clinicName', clinicInfo.clinicName);
        formData.append('address', clinicInfo.address);
        formData.append('clinicNumber', clinicInfo.clinicNumber);
        formData.append('services', selectedService);
        formData.append('accountNumber', clinicInfo.accountNumber);
        formData.append('IFSCcode', clinicInfo.IFSCcode);
        try {
            const response = await axios.post(`${API}/clinics/${doctorId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            setCoilDoctorClinicData((prevData) => [...prevData, response.data])
        } catch (error) {
            console.error("Error:", error);
        }
        props.onSubmit()
        props.onClose()
    }

    return (
        <div className="col-lg-12">
            <form onSubmit={sendClinicInfo}>
                <div className="text-left">
                    <label className="font_weight">Clinic Logo</label>
                    <MainInput
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleFileChange}
                        name="clinicLogo">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">Clinic Name *</label>
                    <MainInput
                        type="text"
                        name="clinicName"
                        onChange={handleChange}
                        value={clinicInfo.clinicname}
                        placeholder="Enter clinic name">
                    </MainInput>
                    {errors.name && <div className="validation mb-1">{errors.name}</div>}
                </div>

                <label className="font_weight">Location *</label>
                <MainInput
                    type="text"
                    name="address"
                    value={clinicInfo.address}
                    onChange={handleChange}
                    placeholder="Enter clinic address">
                </MainInput>
                {errors.address && <div className="validation mb-1">{errors.address}</div>}

                <label className="font_weight">Clinic Number</label>
                <MainInput
                    type="text"
                    name="clinicNumber"
                    onChange={handleChange}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    maxLength={10}
                    value={clinicInfo.clinicnumber}
                    placeholder="Clinic Number (+XX)">
                </MainInput>
                <div className="form-group">
                    <label className="font_weight">Account Number</label>
                    <MainInput
                        type="text"
                        name="accountNumber"
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        value={clinicInfo.clinicnumber}
                        placeholder="Account Number">
                    </MainInput>
                </div>
                <div className="form-group">
                    <label className="font_weight">IFSC Code</label>
                    <MainInput
                        type="text"
                        name="IFSCcode"
                        onChange={handleChange}
                        pattern="[+-]?\d+(?:[.,]\d+)?"
                        maxLength={15}
                        value={clinicInfo.IFSCcode}
                        placeholder="IFSC code">
                    </MainInput>
                </div>
                <div align='left' className="patientData mt-2 mb-2 font_weight" >
                    Clinic Services
                </div>
                <Autocomplete
                    disablePortal={true}
                    disableClearable
                    disableCloseOnSelect
                    multiple={true}
                    className='autocompleteWidth'
                    id={servicess._id}
                    value={selectedService.name}
                    onChange={handleService}
                    getOptionLabel={(servicess) => `${servicess.name}`}
                    options={servicess}
                    renderInput={(params) =>
                        <TextField {...params}
                            label="Service" />}
                />
                {errors.services && <div className="validation mt-1">{errors.services}</div>}
                <div align="center" className="m-2 mt-4 ">
                    <MainButtonInput />
                </div>
            </form >
        </div >
    );
};
export { AddClinic }
