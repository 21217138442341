import React, { useState } from "react";
import { MainInput } from "../../../mainComponent/mainInput";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import ServicesApi from "../../../services/servicesAPI";
import { useRecoilState } from "recoil";
import { setAddTreatment } from "../../../recoil/atom/setAddTreatement";
import { validateForm } from "../../Dashboard-card/validateForm";

const Treatment = (props) => {
    const { doctorId } = props
    const [treatment, setTreatment] = useState([]);
    const [services, setServices] = useRecoilState(setAddTreatment);
    const [errors, setErrors] = useState({});
    const { addServices } = ServicesApi()

    function handleChange(event) {
        const { name, value } = event.target;
        setTreatment(prevInput => {
            return {
                ...prevInput,
                [name]: value
            }
        })
    }

    const Save = (e) => {
        e.preventDefault();
        const validation = validateForm({
            treatment
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        const bodyData = {
            doctorId: doctorId,
            name: treatment.name,
            fees: treatment.fees
        }

        addServices(bodyData)
            .then((res) => {
                setServices(services.concat(res))
            });
        props.onSubmit()
    }

    return (
        <div className="width_100">
            <form onSubmit={Save}>
                <div className="form-group">
                    <label className="font_weight">Treatment Name</label>
                    <MainInput
                        type="text"
                        name="name"
                        onChange={handleChange}
                        value={treatment.name}
                        placeholder="Enter Treatment name">
                    </MainInput>
                    {errors.name && <div className="validation mb-1">{errors.name}</div>}
                </div>

                <label className="font_weight">Fees</label>
                <MainInput
                    type="text"
                    name="fees"
                    value={treatment.fees}
                    onChange={handleChange}
                    placeholder="Enter fees">
                </MainInput>
                {errors.fees && <div className="validation">{errors.fees}</div>}
                <div className="mt-5" align='center'>
                    <MainButtonInput value="Save" />
                </div>
            </form >
        </div >
    );
};
export { Treatment }
