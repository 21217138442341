import React, { useEffect, useState } from "react";
import ReportApi from "../../../services/ReportApi";
import Toaster from "../../Toaster";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { SecondaryButtonInput } from "../../../mainComponent/secondaryButtonInput";

export default function Premedication(props) {
    const { insertPremedicationNote, getMedicineReport } = ReportApi();
    const [note, set_note] = useState({})
    const { onChange, reportId } = props;

    useEffect(() => {
        premedicationData()
    }, [])

    const handleChange = (event) => {
        const { name, value } = event.target;
        set_note({ ...note, [name]: value });
    }

    const addNode = () => {
        if (note.premedication_note || note.investigation_note) {
            const bodyData = {
                "premedication_note": note.premedication_note,
                "investigation_note": note.investigation_note,
            }
            insertPremedicationNote({ reportId }, bodyData)
            toast.success("Saved Successfully!")
        }
    }
    const premedicationData = () => {
        getMedicineReport({ reportId })
            .then((res) => {
                set_note(res[0])
            })
    }
    return (
        <>
            <div className='row'>
                <div align='left' className="col-md-6" >
                    <span className='mb-2'>Premedication Note</span>
                    <textarea
                        type="text"
                        value={note.premedication_note}
                        onChange={handleChange}
                        className="form-control"
                        name="premedication_note"
                        placeholder="Write Something"
                    />
                </div>
                <div align='left' className="col-md-6"  >
                    <span className='mb-2'>Investigation Note</span>
                    <textarea
                        type="text"
                        value={note.investigation_note}
                        onChange={handleChange}
                        className="form-control"
                        name="investigation_note"
                        placeholder="write something"
                    />
                </div>
            </div>
            <div className="row float-right">
                <div className="text-left mt-2">
                    <MainButtonInput onClick={addNode}> Save</MainButtonInput>
                </div>
                <div className="text-left mt-2 ml-2">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>

            <div className="row float-right">
                <Toaster />
            </div>
        </>
    )
}