import React, { useEffect, useState } from "react";
import { setDoctorClinic } from "../../../../recoil/atom/setDoctorClinic";
import { useRecoilState } from "recoil";
import { MainButtonInput } from "../../../../mainComponent/mainButtonInput";
import ClinicApi from "../../../../services/ClinicApi";
import { Modal } from "react-bootstrap";
import { AddClinic } from "./addclinic";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Box, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SearchClinic = (props) => {
    const { doctorId } = props;
    const [coilDoctorClinicData, setCoilDoctorClinicData] = useRecoilState(setDoctorClinic)
    const [clinicInfo, setClinicInfo] = useState([]);
    const [clinicSave, setClinicSave] = useState('')
    const { getClinic, addClinic } = ClinicApi()
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const isMobile = useMediaQuery('(max-width:768px)')

    useEffect(() => {
        fetchclinic()
    }, [])

    const handleShow = () => {
        setShow(true);
        setOpen(true);
    }

    const handleClose = () => {
        setShow(false);
        setOpen(false);
    }

    const fetchclinic = (() => {
        getClinic()
            .then((res) => {
                setClinicInfo(res.result)
            })
    })

    const handleChange = (event, selectedValue) => {
        event.preventDefault()
        setClinicSave(selectedValue)
    }

    const validateClinicSave = () => {
        if (!clinicSave) {
            setError("Please select a clinic");
            return false;
        }
        return true;
    };

    function sendClinicInfo(e) {
        e.preventDefault();
        if (!validateClinicSave()) {
            return;
        }
        const newClinicData = {
            clinicId: clinicSave._id
        }
        addClinic(newClinicData, doctorId)
            .then((res) => {
                setCoilDoctorClinicData(coilDoctorClinicData.concat(clinicSave))
            });
        props.onSubmit()
        setShow(false)
        setOpen(false)
    }

    return (
        <>
            <div className="width_100">
                <form onSubmit={sendClinicInfo}>
                    <div className="form-group">
                        <label className="font_weight">Search Clinic</label>
                        <div className="row">
                            <div className="col-md-8">
                                <Autocomplete
                                    disablePortal={true}
                                    disableClearable
                                    disableCloseOnSelect
                                    autoHighlight
                                    value={clinicSave.clinicName}
                                    onChange={handleChange}
                                    getOptionLabel={(data) => `${data.clinicName} (${data.address} )`}
                                    renderOption={(props, option) =>
                                    (<Box component="li" sx={{ mr: 2, flexShrink: 0 }}{...props}>
                                        {option.clinicName + '(' + option.address + ')'}
                                    </Box>)}
                                    options={clinicInfo}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            label="Enter Clinic Name"
                                        />
                                    }
                                />
                                {error && <div className="validation mt-1">{error}</div>}
                            </div>

                            <div className="col-md-3 margin_btn_top" align='right'>
                                <MainButtonInput className='marginLeft' value="Add" >
                                    ADD
                                </MainButtonInput>
                            </div>
                        </div>

                    </div>
                </form >
                <>
                    {isMobile ? (
                        <Drawer anchor="bottom" open={open} onClose={handleClose}>
                            <div className='drawerTitle underline' >
                                <Typography variant="h6">Add Clinic</Typography>
                                <IconButton onClick={handleClose} aria-label="close">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <div className='p-4'>
                                <AddClinic
                                    onClose={props.onSubmit}
                                    doctorId={doctorId}
                                    onSubmit={handleClose}
                                />
                            </div>
                        </Drawer>
                    ) : (
                        <div className="modalbtn">
                            <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Clinic</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <AddClinic
                                        onClose={props.onSubmit}
                                        doctorId={doctorId}
                                        onSubmit={handleClose}
                                    />
                                </Modal.Body>
                            </Modal>
                        </div>
                    )}
                </>
                <div className="mt-5" align='right'>
                    <MainButtonInput onClick={handleShow} >ADD ANOTHER CLINIC </MainButtonInput>
                </div>
            </div >

        </>
    );
};
export { SearchClinic }
