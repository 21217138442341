import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { MainInput } from '../../../mainComponent/mainInput';
import LabWorkApi from '../../../services/LabWorkApi';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { Box } from '@mui/material';
import AuthApi from '../../../services/AuthApi';
import AppointmentsApi from '../../../services/AppointmentsApi';
import { useDropzone } from 'react-dropzone';
import { useRecoilState } from 'recoil';
import { setLabworkData } from '../../../recoil/atom/setLabWorkData';
import { API } from '../../../config';
import axios from 'axios';
import { validateForm } from '../validateForm';

export default function AddLabWorkTable(props) {
    const { doctorId } = props
    const [saveWork, setSaveWork] = useState("");
    const [saveLab, setSaveLab] = useState("");
    const [savepatient, setSavePatient] = useState("");
    const [saveClinic, setSaveClinic] = useState("");
    const [saveTooth, setSaveTooth] = useState([]);
    const [WokType, setWorkType] = useState([]);
    const [patient, setPatient] = useState([]);
    const [lab, setLab] = useState([]);
    const [clinic, setClinic] = useState([]);
    const [labWokData, setLabWorkData] = useState([]);
    const [givenDate, setGivenDate] = useState();
    const [rcvDate, setRcvDate] = useState();
    const [selectedTeeth, setSelectedTeeth] = useState([])
    const [labwork, setLabwork] = useRecoilState(setLabworkData)
    const { getWorkTypes, getlabsInfo, addLabWork } = LabWorkApi()
    const { getDrInfo } = AuthApi()
    const { getAppointmentsDetails } = AppointmentsApi()
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getLabWorkData();
        generateOctalCombinations();
    }, [])

    const getLabWorkData = () => {
        getWorkTypes()
            .then((result) => {
                setWorkType(result)
            })

        getlabsInfo()
            .then((result) => {
                setLab(result)
            })

        getDrInfo({ doctorId })
            .then((result) => {
                if (result.result[0]["clinicList"]) {
                    const clinics = result.result[0]["clinicList"]
                    setClinic(clinics)
                } else {
                    setClinic('')
                }
            })

        getAppointmentsDetails(doctorId)
            .then((result) => {
                if (result.test) {
                    const res = result.test.filter(function (item, index) {
                        return index === result.test.findIndex((obj) => {
                            if (item.patientId === obj.patientId)
                                return item
                        })
                    })
                    const patientNames = res.map(item => item.patientDetails[0].name);
                    setPatient(patientNames)
                } else {
                    return null
                }
            })
    };

    const handleFileChange = (event) => {
        setFile(event.target.files);
    };
    const addGivenDatePicker = (date) => {
        setGivenDate(date)
    }

    const addRcvDatePicker = (date) => {
        setRcvDate(date)
    }

    const handleWorkType = (event, selectedValue) => {
        event.preventDefault()
        setSaveWork(selectedValue)
    }

    const handleLab = (event, selectedValue) => {
        event.preventDefault()
        setSaveLab(selectedValue)
    }

    const handlePatients = (event, selectedValue) => {
        event.preventDefault()
        setSavePatient(selectedValue)
    }

    const handleClinics = (event, selectedValue) => {
        event.preventDefault()
        setSaveClinic(selectedValue)
    }

    const handleTooths = (event, selectedValue) => {
        event.preventDefault()
        setSaveTooth(selectedValue)
    }

    //for all input onchange method
    const handleInputChange = event => {
        const { name, value } = event.target;
        setLabWorkData({ ...labWokData, [name]: value });
    };

    function generateOctalCombinations() {
        let combinations = [];

        for (let x = 1; x <= 4; x++) {
            for (let y = 1; y <= 8; y++) {
                // Convert x and y to octal format
                let octalX = x.toString(16)
                let octalY = y.toString(16)
                combinations.push({
                    value: `${octalX} , ${octalY}`,
                });
            }
        }
        setSelectedTeeth(combinations)
    }
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        const validation = validateForm({
            labWokData,
            saveClinic,
            saveWork,
            saveLab,
            saveTooth,
            savepatient
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        const bodyData = {
            'doctorId': doctorId,
            'workname': labWokData.name,
            'worktype': saveWork.worktype,
            'price': saveWork.price,
            'labname': saveLab.labname,
            'labcontact': saveLab.labcontact,
            'patient': savepatient,
            'clinic': saveClinic.clinicName,
            'toothnumber': saveTooth,
            // 'givendate': givenDate,
            // 'recievedate': rcvDate,
            'comments': labWokData.comment,
        }

        await addLabWork(bodyData)
            .then(async (response) => {
                setLabwork(labwork.concat(response))
                const formData = new FormData();
                if (file) {
                    Array.from(file).forEach((singleFile) => formData.append("photo", singleFile));
                    try {
                        await axios.post(`${API}/labreports/${response._id}`, formData, {
                            headers: { "Content-Type": "multipart/form-data" }
                        });
                        alert("file uploaded successfully")
                    } catch (error) {
                        console.error("Error uploading files", error.response ? error.response.data : error.message);
                    }
                }
            })

        props.onClick()
    }

    return (
        <form onSubmit={onSubmitHandler}>
            <div className="row">
                <div className="col-md-6 ">
                    <div className='mt-2'>
                        <label className="font_weight ">Work Name *</label>
                        <MainInput
                            type="text"
                            value={labWokData.name}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder='Name'
                            name="name"
                        />
                        {errors.name && <div className="validation">{errors.name}</div>}
                    </div>

                    <div>
                        <label className="font_weight ">Work type *</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveWork.worktype}
                            onChange={handleWorkType}
                            getOptionLabel={(data) => `${data.worktype}`}
                            options={WokType}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.worktype}</Box>)}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Work type"
                                />}
                        />
                        {errors.worktype && <div className="validation">{errors.worktype}</div>}
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Price</label>
                        <MainInput
                            type="text"
                            value={saveWork.price}
                            className="form-control"
                            placeholder='Price'
                            name="price"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Patient List *</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={savepatient}
                            onChange={handlePatients}
                            getOptionLabel={(data) => `${data}`}
                            options={patient}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"patients not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Patient Name"
                                />
                            }
                        />
                        {errors.patientName && <div className="validation">{errors.patientName}</div>}
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Given Date</label>
                        <div className="form-group">
                            <DatePicker
                                className="datepicker"
                                onChange={addGivenDatePicker}
                                value={givenDate}
                                clearIcon={null}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className='mt-2'>
                        <label className="font_weight">Tooth Number *</label>
                        <Autocomplete
                            multiple={true}
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveTooth}
                            onChange={handleTooths}
                            getOptionLabel={(data) => `${data}`}
                            options={selectedTeeth.map((n) => `${n.value}`)}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option}</Box>)}
                            noOptionsText={"patients not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Select Tooth"
                                />
                            }
                        />
                        {errors.toothNumbers && <div className="validation">{errors.toothNumbers}</div>}
                    </div>

                    <div className='mt-3'>
                        <label className="font_weight">Lab Name *</label>
                        <Autocomplete
                            id={lab._id}
                            autoHighlight
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveLab.labname}
                            onChange={handleLab}
                            getOptionLabel={(data) => `${data.labname}`}
                            options={lab}
                            noOptionsText={"Lab not available"}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.labname}</Box>)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Lab Name"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />}
                        />
                        {errors.labName && <div className="validation">{errors.labName}</div>}
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Contact</label>
                        <MainInput
                            type="text"
                            value={saveLab.labcontact}
                            className="form-control"
                            placeholder='Contact'
                            name="labcontact"
                        />
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Clinic List *</label>
                        <Autocomplete
                            disablePortal={true}
                            disableClearable
                            disableCloseOnSelect
                            value={saveClinic.clinicName}
                            onChange={handleClinics}
                            getOptionLabel={(data) => `${data.clinicName}`}
                            renderOption={(props, option) => (<Box component="li" sx={{ mr: 2, flexShrink: 0 }} {...props}>{option.clinicName}</Box>)}
                            options={clinic}
                            noOptionsText={"Clinic not available"}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Clinic Name"
                                />
                            }
                        />
                        {errors.clinicName && <div className="validation">{errors.clinicName}</div>}
                    </div>

                    <div className='mt-2'>
                        <label className="font_weight">Recived Date</label>
                        <div className="form-group">
                            <DatePicker
                                className="datepicker"
                                onChange={addRcvDatePicker}
                                value={rcvDate}
                                clearIcon={null}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-2'>
                <label className="font_weight">Comment</label>
                <textarea
                    type="text"
                    value={labWokData.comment}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder='Optional'
                    name="comment"
                />
            </div>

            <div className='mt-2'>
                <label className="font_weight">Upload Your Documents</label>
                <MainInput
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    onChange={handleFileChange}
                    name="photo"
                    multiple
                />
                <span>You can only upload a maximum of 2 files.</span>
            </div>

            <div className="text-center mt-2 add_top_30">
                <MainButtonInput>Save</MainButtonInput>
            </div>
        </form>
    )
} 