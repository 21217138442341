import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from "../../../mainComponent/mainInput";
import { MainSelect } from '../../../mainComponent/mainSelect';
import AuthApi from "../../../services/AuthApi";
import { useRecoilState } from "recoil";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";
import EducationalApi from "../../../services/EducationalApi";
import { requestNotificationPermission } from "../../../firebase.config";

export default function CreateForm(props) {
    //for show otp input
    const { mobile, _id } = props;
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState(false);
    const [doctorId, setdoctorId] = useRecoilState(setDoctorId);
    const [drspecialization, setDrSpecialization] = useState(null)
    const [specialization, setSpecialization] = useState([])
    const [data, setData] = useState(
        {
            password: '',
            confirmPassword: ''
        }
    );
    const { signUp ,saveDoctorToken } = AuthApi();
    const { fetchDrSpecialization } = EducationalApi();
    const navigate = useNavigate();

    useEffect(() => {
        fetchSpecializations()
    }, [])

    const fetchSpecializations = () => {
        fetchDrSpecialization()
            .then((res) => {
                setDrSpecialization(res);
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const doctorToken = async (doctorId) => {
        try {
            const currentToken = await requestNotificationPermission();
            console.log('Generated Token:', currentToken);  // Log the token
            if (currentToken) {
                saveDoctorToken(doctorId, { doctorToken: currentToken })
                    .then(() => {
                        console.log('Doctor token saved for doctorId:', doctorId);
                    })
                    .catch(err => {
                        console.error('Error saving doctor token:', err);
                    });
            }
        } catch (error) {
            console.error('Error generating doctor token:', error);
        }
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        const password = data.password.trim();
        const confirmPassword = data.confirmPassword.trim();
        if (mobile.length < 10 || !/^\d+$/.test(mobile)) {
            setIsError('Invalid mobile number');
            return;
        }
        if (password === '' || confirmPassword === '') {
            setIsError('Password fields cannot be empty');
            return;
        }

        if (password !== confirmPassword) {
            alert('Passwords do not match');
            return;
        }
        const bodyData = {
            specialization: specialization,
            mobile: mobile,
            password: password,
            _id: _id
        };
        signUp(bodyData)
            .then(response => {
                if (response.data.message) {
                    setError('Sign up failed: ' + response.data.message);
                } else {
                    const doctorId = response.data._id;
                    setdoctorId(doctorId);
                    doctorToken(doctorId)
                    if (response.data.isLoggedIn === true ) {
                        navigate(`/profile/${doctorId}`);
                    } else {
                        setError('You have entered an invalid credentails');
                    }
                }
            })
            .catch(err => {
                console.error('Sign-up error:', err);
                setError('An error occurred during sign-up');
            });
    };


    return (
        <>
            <div className="col-md-12" align='left'>
                {error === true ? <div className="validation mb-2 ml-3">
                    Mobile number already exist login to continue.
                </div>
                    : null}
            </div>

            <lable className="mb-2 fontSize">Enter MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="password"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="New MPIN"
                    required
                >
                </MainInput>
            </div>

            <lable className="mb-2 fontSize">Confirm MPIN</lable>
            <div className="col-md-12">
                <MainInput
                    type="password"
                    name="confirmPassword"
                    maxLength={6}
                    pattern="[+-]?\d+(?:[.,]\d+)?"
                    onChange={handleChange}
                    placeholder="Confirm MPIN"
                    required>
                </MainInput>
            </div>
            <lable className="mb-2 fontSize">Specialization</lable>
            <div className="col-md-12 mb-2">
                <MainSelect
                    name="specialization"
                    className="form-control"
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}>
                    <option>Select specialization</option>
                    {drspecialization ?
                        <>
                            {drspecialization && drspecialization.map((spe, index) => (
                                <option className="form-control" key={index}>{spe.specialization}</option>
                            ))}
                        </>
                        : null}
                </MainSelect>
            </div>
            <div className="mt-2">
                <MainButtonInput onClick={handleSubmit}>Login</MainButtonInput>
            </div>
            {isError === true ? <div className="validation mb-2 ml-3">
                Fill all fields correctly
            </div>
                : null}
        </>
    )
}