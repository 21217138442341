import React from 'react';
import Autocomplete from '@mui/lab/Autocomplete';
import TextField from '@mui/material/TextField';
import { useState, useEffect } from 'react';
import GetSymptomsData from './GetSymptomsData'
import ReportApi from '../../../services/ReportApi';
import Toaster from '../../Toaster';
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useRecoilState } from 'recoil';
import { setFetchSymptoms } from '../../../recoil/atom/setFetchSymptoms';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { toast } from 'react-toastify';
import { THEME_COLOR } from '../../../config';
import { SecondaryButtonInput } from '../../../mainComponent/secondaryButtonInput';
import { validateForm } from '../../Dashboard-card/validateForm';

export default function Symptoms(props) {
    const { onChange, reportId } = props
    const [symptoms, setSymptoms] = useState([])
    const [saveSymptoms, setSaveSymptoms] = useState([])
    const [fetchSymptoms, setfetchSymptoms] = useRecoilState(setFetchSymptoms)
    const { symptomsData, insertSymptoms, insertSymptom_masterTable } = ReportApi();
    const [inputSymptom, setInputSymptoms] = useState([])
    const [errors, setErrors] = useState({})

    useEffect(() => {
        getSymptomsData();
    }, [])

    const getSymptomsData = () => {
        symptomsData()
            .then((result) => {
                setSymptoms(result)
            })
    };

    const handleChange = (e, selectedValue) => {
        e.preventDefault()
        setSaveSymptoms(selectedValue)
    }

    const addInputBox = () => {
        const value = [...inputSymptom, []]
        setInputSymptoms(value)
    }

    const handleInputChange = (onChangeValue, i) => {
        const inputData = [...inputSymptom]
        inputData[i] = onChangeValue.target.value;
        setInputSymptoms(inputData)
    }

    const handleDelete = (i) => {
        const deleteVal = [...inputSymptom]
        deleteVal.splice(i)
        setInputSymptoms(deleteVal)
    }

    const addSymptoms = () => {
        const validation = validateForm({
            saveSymptoms
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        saveSymptoms.push(...inputSymptom)
        const bodyData = {
            "symptoms": saveSymptoms,
        }
        insertSymptoms({ reportId }, bodyData)
            .then((res) => {
                setfetchSymptoms(fetchSymptoms.concat(res.data.symptoms))
                inputSymptom.map((item, i) => {
                    const other = {
                        "symptoms": item,
                    }
                    insertSymptom_masterTable(other)
                    return item
                })
                toast.success("Saved Successfully!")
            })
    }

    return (
        <div>
            <div className=' row'>
                <div className=' left col-md-6'>
                    <label className='d-flex'>Choose Symptoms</label>
                    <div className='row'>
                        <div className=' left col-md-6'>
                            <Autocomplete
                                id={symptoms._id}
                                disablePortal={true}
                                disableClearable
                                multiple={true}
                                disableCloseOnSelect
                                value={saveSymptoms.name}
                                onChange={handleChange}
                                options={symptoms.map((n) => `${n.name}`)}
                                noOptionsText={"Symptom not availabel please add"}
                                renderInput={(params, index) =>
                                    <TextField
                                        key={index}
                                        {...params}
                                        label="Choose a Symptoms"
                                    />}
                            />
                            {errors.symptoms && <div className="validation">{errors.symptoms}</div>}
                        </div>
                        <div className='ml-2'>
                            <Link to="#" onClick={() => addInputBox()}>
                                <AddOutlinedIcon style={{ fontSize: 25, color: { THEME_COLOR }, marginLeft: 5, marginTop: 5 }} />
                            </Link>
                        </div>
                    </div>
                    <div>
                        {
                            inputSymptom.map((data, i) => {
                                return (
                                    <div key={i} className='row add_top_20 d-flex'>
                                        <span style={{ width: 250 }}>
                                            <input
                                                type="text"
                                                className="form-control "
                                                onChange={(e) => handleInputChange(e, i)}
                                                placeholder="Enter your symptoms"
                                            />
                                        </span>
                                        <span className="width_10">
                                            <Link to="#" onClick={() => handleDelete(i)}>
                                                <DeleteForeverIcon style={{ fontSize: 25, marginTop: 7, marginLeft: 5 }} />
                                            </Link>
                                        </span>

                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='col-md-4'>
                    <GetSymptomsData reportId={reportId} />
                </div>
            </div>
            <div className="row float-right">
                <div className="">
                    <MainButtonInput onClick={addSymptoms}>Save Symptom</MainButtonInput>
                </div>
                <div className="ml-2">
                    <SecondaryButtonInput onClick={onChange}>Next</SecondaryButtonInput>
                </div>
            </div>
            <Toaster />
        </div>
    )
}