import React, { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import avatarImage from '../../../img/profile.png';
import { MainButtonInput } from "../../../mainComponent/mainButtonInput";
import { MainInput } from '../../../mainComponent/mainInput';
import { useRecoilState } from "recoil";
import Toaster from "../../Toaster";
import "react-toastify/dist/ReactToastify.css";
import { API } from "../../../config";
import { toast } from "react-toastify";
import axios from "axios";
import { Wrapper } from "../../../mainComponent/Wrapper";
import { MainNav } from "../../../mainComponent/mainNav";
import UserLinks from "../../Dashboard-card/partial/uselinks";
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { setDoctorId } from "../../../recoil/atom/setDoctorId";

function AddNewDoctor() {
    const { doctorId } = useParams();
    const [data, setData] = useState([]);
    const [helperData, setHelpeData] = useRecoilState(setHelperData)
    const [DoctorId] = useRecoilState(setDoctorId)
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };

    const handleFileChange = (event) => {
        setData({ ...data, ['photo']: URL.createObjectURL(event.target.files[0]) })
        setFile(event.target.files[0]);
    };

    const handleValidation = () => {
        const tempErrors = {};
        let formIsValid = true;

        // Name validation
        if (!data.name) {
            tempErrors.name = "Name is required";
            formIsValid = false;
        }

        // Gender validation
        if (!data.gender) {
            tempErrors.gender = "Gender is required";
            formIsValid = false;
        }

        // Email validation
        if (!data.personalEmail) {
            tempErrors.personalEmail = "Email is required";
            formIsValid = false;
        } else if (!/\S+@\S+\.\S+/.test(data.personalEmail)) {
            tempErrors.personalEmail = "Email is not valid";
            formIsValid = false;
        }

        // Address validation
        if (!data.address) {
            tempErrors.address = "City & Area is required";
            formIsValid = false;
        }

        setErrors(tempErrors);
        setIsValid(formIsValid);
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!handleValidation()) {
            return;
        }
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('gender', data.gender);
        formData.append('personalEmail', data.personalEmail);
        formData.append('address', data.address);
        formData.append('photo', file);
        formData.append('bio', data.bio);
        formData.append('DoctorId', DoctorId);
        try {
            const response = await axios.post(`${API}/addnewdoctor/${doctorId}`, formData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            const result = await response.data
        } catch (error) {
            console.error("Error:", error);
        }
        toast.success('Data save successfully')
        navigate(`/newdoctor/${DoctorId}`)
    };

    return (
        <>
            <Wrapper>
                <MainNav>
                    <ul className="clearfix">
                        <li>
                            <Link to={`/newdoctor/${DoctorId}`}>
                                <i className="arrow_back backArrow" title="back button"></i>
                            </Link>
                        </li>
                        <li className='float-none' style={{ fontSize: 'inherit' }} >Add Doctor</li>
                    </ul>
                </MainNav>
                <div className="row ">
                    <div className='width_16'>
                        <div className='dash row'>
                            <UserLinks
                                doctorId={doctorId}
                                helperId={helperData._id}
                                accessModule={helperData.access_module}
                            />
                        </div>
                    </div>
                    <div className='width_84'>
                        <div className='white-box pb-5'>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="row mb-10">
                                            <div className="col-md-5">
                                                <div className="doctorphoto">
                                                    {data.photo ?
                                                        <img src={data.photo} className="doctorphotoStyle" alt="doctorPhoto" />
                                                        : <img src={avatarImage} alt="doctorPhoto" className="doctorphotoStyle" />
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-left">
                                                    <label className="font_weight">Doctor photo</label>
                                                </div>
                                                <MainInput
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    onChange={handleFileChange}
                                                    name="photo"
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-12">
                                            <div className="col-md-6">
                                                <div className="text-left">
                                                    <label className="font_weight">Gender *</label>
                                                </div>
                                                <div className="radioButton" align='left'>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='female'
                                                        name="gender"
                                                        onChange={handleInputChange}
                                                        checked={data.gender === 'female'}
                                                    />
                                                    <span>Female</span>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='male'
                                                        name="gender"
                                                        checked={data.gender === 'male'}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span>Male</span>
                                                    <input
                                                        className="radio_button"
                                                        type="radio"
                                                        value='other'
                                                        name="gender"
                                                        checked={data.gender === 'other'}
                                                        onChange={handleInputChange}
                                                    />
                                                    <span>Other</span>
                                                </div>
                                                {errors.gender && <div  style={{textAlign:'left', color:'red', marginBottom:'10px'}}>{errors.gender}</div>}
                                            </div>
                                        </div>

                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="address">City & Area *</label>
                                        </div>
                                        <div align='left'>
                                            <MainInput
                                                name='address'
                                                value={data.address}
                                                placeholder="Address"
                                                onChange={handleInputChange}
                                            />
                                            {errors.address && <div  style={{textAlign:'left', color:'red'}}>{errors.address}</div>}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="name">Full Name *</label>
                                        </div>
                                        <MainInput
                                            name="name"
                                            value={data.name}
                                            onChange={handleInputChange}
                                            placeholder="Name"
                                        />
                                        {errors.name && <div className="validation">{errors.name}</div>}
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="email">EmailId *</label>
                                        </div>
                                        <MainInput
                                            type="email"
                                            value={data.personalEmail}
                                            name="personalEmail"
                                            onChange={handleInputChange}
                                            placeholder="emailId"
                                        />
                                        {errors.personalEmail && <div className="validation">{errors.personalEmail}</div>}
                                        <div className="text-left">
                                            <label className="font_weight" htmlFor="bio">Bio</label>
                                        </div>
                                        <div align='left'>
                                            <textarea name='bio'
                                                value={data.bio}
                                                placeholder="Bio"
                                                className="form-control"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className=" mr-2 float-right">
                                    <MainButtonInput> Save</MainButtonInput>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row float-right">
                        <Toaster />
                    </div>
                </div>
            </Wrapper>
        </>
    );
}

export { AddNewDoctor };
