import axios from 'axios';
import { API } from '../config';

export default function AuthApi() {
    const login = async ({ mobile }) => {
        try {
            const result = await axios.post(`${API}/loginotp`, { mobile })
            return result;
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }
    };

    const loginOtp = async ({ getOTP, _id }) => {
        try {
            const result = await axios.post(`${API}/otp`, { getOTP, _id });
            return result;
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }
    };
    const ForgetMpinLogin = async ({ mobile }) => {
        try {
            const result = await axios.post(`${API}/forgetmpinlogin`, { mobile });
            return result;
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }
    };

    const FetchDoctorData = async ({ doctorId }) => {
        try {
            const result = await axios.get(`${API}/fetchData/${doctorId}`);
            return result.data;
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }
    };

    const submitDoctorInformation = async (doctorId, formData) => {
        try {
            const result = await axios.post(`${API}/insertPersonalInfo/${doctorId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })
            return result
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }

    }

    const getDrInfo = async ({ doctorId }, currentPage, pageSize) => {
        try {
            const result = await axios.get(`${API}/doctor/${doctorId}?page=${currentPage}&pageSize=${pageSize}`);
            return result.data;
        }
        catch (err) {
            return err
        }
    }

    const signUp = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/signup`, bodyData)
            return result;
        }
        catch (err) {
            return err
        }
    };

    const signIn = async ({ mobile, password }) => {
        try {
            const result = await axios.post(`${API}/signin`, { mobile, password })
            return result;
        }
        catch (err) {
            return {
                status: "error",
                message: err.message
            }
        }
    };
    const forgotMpin = async (bodyData) => {
        try {
            const result = await axios.post(`${API}/forgotmpin`, bodyData)
            return result;
        }
        catch (err) {
            return err
        }
    };
    const saveDoctorToken = async(doctorId, { doctorToken: currentToken }) => {
        try {
            const result = await axios.post(`${API}/savedoctortoken/${doctorId}`, { doctorToken: currentToken })
            return result;
        }
        catch (err) {
            return err
        }
    };
    const getNotification = async (doctorId) => {
        try {
            const result = await axios.get(`${API}/getnotification/${doctorId}`)
            return result.data;
        }
        catch (err) {
            return err
        }
    };
    const notifyDoctor = async (notificationData) => {
        try {
            const result = await axios.post(`${API}/notifydoctor`, notificationData);
            return result.data
        }
        catch (err) {
            return err
        }
    }
    const updateToken = async (userType, token) => {
        try {
            const result = await axios.post(`${API}/updatetoken`, userType, token);
            return result.data
        }
        catch (err) {
            return err
        }
    }

    return {
        login,
        loginOtp,
        signUp,
        signIn,
        FetchDoctorData,
        submitDoctorInformation,
        getDrInfo,
        forgotMpin,
        ForgetMpinLogin,
        saveDoctorToken,
        getNotification,
        notifyDoctor,
        updateToken
    }
}