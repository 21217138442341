import React, { useEffect, useState } from 'react';
import { Link, Outlet, useParams } from "react-router-dom";
import { FetchDoctorPersonalDetails } from "./Personal/Partial/fetchDoctorPersonalDetails";
import { MainNav } from "../../mainComponent/mainNav";
import UserLinks from "../Dashboard-card/partial/uselinks";
import { Wrapper } from "../../mainComponent/Wrapper";
import { setHelperData } from "../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import DashboardCalendar from '../Dashboard-card/DashboardCalendar';
import Loader from '../Dashboard-card/Loader';

export default function DoctorProfile() {
    const { doctorId } = useParams();
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [isLoading, setIsLoading] = useState(true);

    setTimeout(() => {
        setIsLoading(false);
    }, 3000);

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li className='float-none ' style={{ fontSize: 'inherit' }}>General info</li>
                    <li className='mt-2'>
                        <Link
                            to="edit">
                            <i className="icon_pencil-edit " title="Edit profile" />
                        </Link>
                    </li>
                </ul>
            </MainNav>

            <div className='row'>
                <div className='width_16'>
                    <div className="dash row">
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module} />
                    </div>
                </div>
                <div className='width_84'>
                    <div className="common_box ">
                        {isLoading ?
                            <div className='loader-container'>
                                <Loader />
                            </div>
                            :
                            <>
                                <div className='full-width'>
                                    <div className='row'>
                                        <DashboardCalendar doctorId={doctorId} />
                                        <FetchDoctorPersonalDetails doctorId={doctorId} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Outlet />
        </Wrapper>
    )
}