import React, { useState, useEffect } from 'react';
import { MainInput } from '../../../mainComponent/mainInput';
import { MainButtonInput } from '../../../mainComponent/mainButtonInput';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material';
import days from '../../../data/days.json';
import Toaster from '../../Toaster';
import "react-toastify/dist/ReactToastify.css";
import { ExpandMore } from '@mui/icons-material';
import { setHelperData } from "../../../recoil/atom/setHelperData";
import { useRecoilState } from "recoil";
import UserLinks from '../partial/uselinks';
import { Wrapper } from '../../../mainComponent/Wrapper';
import { MainNav } from '../../../mainComponent/mainNav';
import TouristAppointmentsApi from '../../../services/TouristAppointmentsApi';
import { MainSelect } from '../../../mainComponent/mainSelect';
import axios from 'axios';
import { API } from '../../../config';
import { validateForm } from '../validateForm';

export default function AddPackages() {
    const { doctorId } = useParams()
    const [helpersData, setHelpersData] = useRecoilState(setHelperData)
    const [activity, setActivity] = useState([]);
    const [daysData, setDaysData] = useState([]);
    const [selectedDays, setSelectedDays] = useState("");
    const [packageData, setPackageData] = useState([]);
    const [showData, setShowData] = useState(false);
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const { saveTourismPackages } = TouristAppointmentsApi();
    const navigate = useNavigate();

    const {
        getActivities,
    } = TouristAppointmentsApi();

    useEffect(() => {
        getActivityTypes();
    }, [])


    const handleFileChange = (event) => {
        setFile(event.target.files);
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPackageData({ ...packageData, [name]: value });
    }

    const getActivityTypes = () => {
        getActivities()
            .then((res) => {
                setActivity(res)
            })
    };

    const handleDescriptionChange = (event, index) => {
        const value = event.target.value;
        const newDaysData = [...daysData];
        newDaysData[index].description = value;
        setDaysData(newDaysData);
    };

    const handleDaysChange = (event) => {
        const value = event.target.value;
        setSelectedDays(value);
        setShowData(true)
        const initialDaysData = Array.from({ length: value }).map(() => ({
            activity: "",
            description: "",
        }));
        setDaysData(initialDaysData);
    };

    const handleActivityChange = (event, index) => {
        const value = event.target.value;
        const newDaysData = [...daysData];
        newDaysData[index].activity = value;
        setDaysData(newDaysData);
    };

    async function handleSubmit(e) {
        e.preventDefault();
        const validation = validateForm({
            packageData,
            selectedDays
        });

        if (!validation.formIsValid) {
            setErrors(validation.errors);
            return;
        }
        const bodyData = {
            "doctorId": doctorId,
            "packagename": packageData.packagename,
            "excerpt": packageData.shortdescription,
            "description": packageData.description,
            "packageprice": packageData.packageprice,
            "activitiesperday": daysData,
            "noofdays": selectedDays,
        }
        await saveTourismPackages(bodyData)
            .then(async (res) => {
                const formData = new FormData();
                if (file) {
                    Array.from(file).forEach((singleFile) => formData.append("photo", singleFile));
                    try {
                        await axios.post(`${API}/updatepackageImg/${res._id}`, formData, {
                            headers: { "Content-Type": "multipart/form-data" }
                        });
                        alert("file uploaded successfully")
                    } catch (error) {
                        console.error("Error uploading files", error.response ? error.response.data : error.message);
                    }
                }
            })
        navigate(`/tourism/${doctorId}`)
    }

    return (
        <Wrapper>
            <MainNav>
                <ul className="clearfix">
                    <li>
                        <Link to={`/tourism/${doctorId}`}>
                            <i className="arrow_back backArrow" title="back button"></i>
                        </Link>
                    </li>
                    <li className='float-none' style={{ fontSize: 'inherit' }}>
                        Add package
                    </li>
                </ul>
            </MainNav>
            <div className="row ">
                <div className='width_16'>
                    <div className='dash row'>
                        <UserLinks
                            doctorId={doctorId}
                            helperId={helpersData._id}
                            accessModule={helpersData.access_module}
                        />
                    </div>
                </div>

                <div className='width_84'>
                    <form encType='multipart/form-data' onSubmit={handleSubmit}>
                        <div className='white-box'>
                            <div className="row p-2">
                                <div className="col-lg-5 ">
                                    <label htmlFor='name' className='helperLabel font_weight float-left'>Package Name</label>
                                    <MainInput
                                        type="text"
                                        name="packagename"
                                        value={packageData.packagename}
                                        onChange={handleChange}
                                        placeholder="Enter Package Name">
                                    </MainInput>
                                    {errors.packagename && <div className="validation">{errors.packagename}</div>}
                                    <div>
                                        <label htmlFor='email' className='helperLabel font_weight float-left'>Package Price</label>
                                    </div>
                                    <MainInput
                                        type="text"
                                        name="packageprice"
                                        value={packageData.packageprice}
                                        onChange={handleChange}
                                        placeholder="package price">
                                    </MainInput>
                                    {errors.packageprice && <div className="validation">{errors.packageprice}</div>}
                                    <div>
                                        <label htmlFor='password' className='helperLabel font_weight float-left'>Package Description</label>
                                    </div>
                                    <div>
                                        <textarea
                                            type="text"
                                            name="shortdescription"
                                            value={packageData.shortdescription}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="short description"
                                        />
                                        {errors.shortdescription && <div className="validation">{errors.shortdescription}</div>}
                                    </div>

                                    <div>
                                        <textarea
                                            type="text"
                                            name="description"
                                            value={packageData.description}
                                            onChange={handleChange}
                                            className="form-control"
                                            placeholder="long description"
                                        />
                                    </div>
                                    <div className='mt-2'>
                                        <label className='helperLabel font_weight float-left'>Upload Photos</label>
                                        <MainInput
                                            type="file"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleFileChange}
                                            name="photo"
                                            multiple
                                        />
                                        {/* <span>You can only upload a maximum of 2 files.</span> */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="helperDiv mb-3">
                                        <label htmlFor="mobile" className="helperLabel font_weight float-left">
                                            No. Of Days
                                        </label>
                                        <MainSelect name="selectedDays" value={selectedDays} onChange={handleDaysChange}>
                                            <option value="">Select Days</option>
                                            {days && days.map((item, index) => (
                                                <option key={index} value={item._id} className="form-control">
                                                    {item.number}
                                                </option>
                                            ))}
                                        </MainSelect>
                                    </div>
                                    {showData ? (
                                        <>
                                            {Array.from({ length: selectedDays }).map((_, index) => (
                                                <div className="helperDiv" key={index}>
                                                    <Accordion defaultActiveKey="0">
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMore />}
                                                            aria-controls="calendar-content"
                                                            id={`calendar-header-${index}`}
                                                        >
                                                            Day {index + 1}
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div className="mb-3">
                                                                <label
                                                                    htmlFor="mobile"
                                                                    className="helperLabel font_weight float-left"
                                                                >
                                                                    Select Activity
                                                                </label>
                                                                <MainSelect
                                                                    value={daysData[index]?.activity || ""}
                                                                    onChange={(e) => handleActivityChange(e, index)}
                                                                >
                                                                    <option value="">Select Activity</option>
                                                                    {activity &&
                                                                        activity.map((item, activityIndex) => (
                                                                            <option
                                                                                key={activityIndex}
                                                                                value={item.activity_name}
                                                                                className="form-control"
                                                                            >
                                                                                {item.activity_name}
                                                                            </option>
                                                                        ))}
                                                                </MainSelect>
                                                            </div>
                                                            <div>
                                                                <label
                                                                    htmlFor="password"
                                                                    className="helperLabel font_weight float-left"
                                                                >
                                                                    Package Description
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <textarea
                                                                    type="text"
                                                                    name="description"
                                                                    value={daysData[index]?.description || ""}
                                                                    onChange={(e) => handleDescriptionChange(e, index)}
                                                                    className="form-control"
                                                                    placeholder="description"
                                                                />
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </div>
                                            ))}
                                        </>
                                    ) : <>
                                        {errors.selectedDays && <div align='right' className="validation">{errors.selectedDays}</div>}
                                    </>
                                    }
                                </div>
                            </div>
                            <div className="text-center add_top_30 pb-2">
                                <MainButtonInput>Save</MainButtonInput>
                            </div>
                        </div>
                    </form>
                    <div className="row float-right toaster">
                        <Toaster />
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}