import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD-oJLUSgaHv1b7Sz4is71DYsEjfxzUCJA",
    authDomain: "fly4smiles-412a2.firebaseapp.com",
    projectId: "fly4smiles-412a2",
    storageBucket: "fly4smiles-412a2.firebasestorage.app",
    messagingSenderId: "314133128034",
    appId: "1:314133128034:web:c5ea7865f1bf345ddd023b",
    measurementId: "G-9ELEGBM876"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// Request notification permission and get FCM token
export const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
        const token = await getToken(messaging, {
            vapidKey: 'BOgFMMcWfayyIQvlr_NfEOv6viRIqN4r-strDuXP2gnGdzf0atkt4VCjH_jNWXMblQGAFDwb6LLgwa5srPWRDSM', // Replace with your VAPID key from Firebase console
        });
        if (token) {
            console.log("FCM Token:", token);
            return token;
          } else {
            console.error("No FCM token received.");
          }
    } else {
        console.error("Permission not granted for notifications.");
    }
};

// Handle foreground messages
export const handleForegroundMessages = () => {
    onMessage(messaging, (payload) => {
        console.log('Message received in foreground:', payload);
        if (Notification.permission === "granted") {
            new Notification(payload.notification.title, {
                body: payload.notification.body,
            });
        }
    });
    console.log('Message received in foreground')
};

export default firebaseConfig;